import React from  'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb from '../../components/breadcumb'

import Contactpage from '../../components/Contactpage'
import FooterSection from '../../components/Footer'
import { Helmet } from 'react-helmet-async';

const ContactPage = () => {
    return(
       <div>
            <Helmet>
                <title>Contact | LDH Transport Management</title>
                <link rel="canonical" href="https://www.ldhtransportmanagement.co.uk/contact" />
            </Helmet>
            <Navbar/>
            <Breadcumb bdtitle={'Contact Us'} bdsub={'Contact'}/>
            <Contactpage/>
            <FooterSection/>
       </div>
    )
}

export default ContactPage;