import React from  'react';

// components
import Navbar from '../../components/Navbar'
import SimpleSlider from '../../components/hero'
import Features from '../../components/features'
import AboutSection from '../../components/about'
import ServiceArea from '../../components/ServiceArea'
import FooterSection from '../../components/Footer'
import { Helmet } from 'react-helmet-async';

const Homepage = () => {
    return(
       <div>
            <Helmet>
                <title>Home | LDH Transport Management</title>
                <link rel="canonical" href="https://www.ldhtransportmanagement.co.uk/" />
            </Helmet>
            <Navbar/>
            <SimpleSlider/>
            <Features/>
            <AboutSection/>
            <ServiceArea/>
            <FooterSection/>
       </div>
    )
}

export default Homepage;