import React from 'react';
import abimg1 from '../../images/features/truck_driving_through_mountains.jpg'

import './style.css'

const AboutSection = () => {

    return(
        <div className="wpo-about-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="wpo-about-img">
                            <img src={abimg1} alt="Artic driving through the mountains"/> 
                        </div>
                    </div>
                    <div className="col-lg-6 sec-p">
                        <div className="wpo-about-content">
                            <div className="wpo-about-icon">
                                <i className="fi flaticon-travel"></i>
                            </div>
                            <h2>Who We Are?</h2>
                            <p>As a class 1 licence holder of 31 years, 6 years transport manager CPC holder and a wealth of experience with 35 years in the Transport industry.</p>
                            <p>We bring a practical knowledge of the industry and good understanding of the day to day issues that come from operating trucks, we will keep your business fully compliant.</p>

                        </div>
                        <div className="signature-section">
                            <div className="si-text">
                                <p>Lee Hamblin</p>
                                <span>Managing Director</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default AboutSection;
