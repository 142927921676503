import React from 'react';
//import {Link} from 'react-router-dom'
//import logo from '../../images/logo/logo.png'
import './style.css'

const getYear = () => {
    return new Date().getFullYear();
}

const FooterSection = () => {

    return(
        <div className="wpo-footer-area">
           {/* <div className="wpo-footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 footer-t">
                            <div className="wpo-footer-logo">
                                <img src={logo} alt=""/>
                            </div>
                            <p>There are many variations of Lorem passages of Lorem Ipsum available, but the majority</p>
                            <p>By injected humour, or randomised words</p>
                            <div className="social">
                                <ul className="d-flex">
                                    <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                    <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                    <li><Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 footer-t"></div>
                        <div className="col-lg-4 col-md-4 col-sm-4 footer-b">
                            <div className="Recent-News-area">
                                <h3>Recent News</h3>
                                <div className="resent-sub">
                                    <p>There are many variations of passages of Lorem</p>
                                    <span><i className="fa fa-clock-o" aria-hidden="true"></i> October 10, 2020</span>
                                </div>
                                <p>There are many variations of passages of Lorem</p>
                                <span><i className="fa fa-clock-o" aria-hidden="true"></i> October 10, 2020</span>
    </div>
                        </div>
                    </div>
                </div>
            </div>*/}
            <div className="wpo-footer-bottom">
                <div className="container">
                    <div className="wpo-footer-bottom-content">
                        <div className="row">
                            <div className="col-12">
                                <span>© Copyright { getYear() } LDH Transport Management. All Rights Reserved.</span>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
     )
        
}

export default FooterSection;
