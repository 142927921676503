import React from 'react';

import {Link} from 'react-router-dom'
import './style.css'

const PricingSection = (props) => {

    return(
        <div className="wpo-pricing-area">
            <div className="container">
                <div className="wpo-section-title text-center">
                    <span>The Best Service at the Best Price</span>
                    <h2>Service Pricing</h2>
                    <p>All services are priced after an initial consultation.<br />Monthly fees for the provision of an external freelance Transport manager CPC Holder, are subject to Operator compliance risk score (OCRS) as below:</p>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row">
                            <div className="col-lg-3 col-l2 col-md-6 col-12">
                                <div className="wpo-price-item">
                                    <div className="wpo-pricing-header">
                                        <h3>Green Operators</h3>
                                        <span>1st Vehicle &pound;450 per month</span>
                                    </div>
                                    <div className="wpo-pricing-table">
                                         <ul>
                                            <li>Each additional vehicle will be charged <br/>&pound;150 per month</li>
                                            <li>&nbsp;<br/>&nbsp;</li>
                                            <li>&nbsp;<br/></li>
                                        </ul>
                                        <div className="wpo-pricing-footer">
                                            <div className="btns">
                                                <div className="btn-style btn-style-4"><Link to="/contact">Contact Now</Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-l2 col-md-6 col-12">
                                <div className="wpo-price-item">
                                    <div className="wpo-pricing-header">
                                        <h3>Amber Operators</h3>
                                        <span>1st Vehicle &pound;500 per month</span>
                                    </div>
                                    <div className="wpo-pricing-table">
                                         <ul>
                                            <li>Each additional vehicle will be charged &pound;150 per month</li>
                                            <li>Price reduces to Green rates once compliance is achieved.</li>
                                            <li>&nbsp;<br/>&nbsp;</li>
                                        </ul>
                                        <div className="wpo-pricing-footer">
                                            <div className="btns">
                                                <div className="btn-style btn-style-4"><Link to="/contact">Contact Now</Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-l2 col-md-6 col-12">
                                <div className="wpo-price-item">
                                    <div className="wpo-pricing-header">
                                        <h3>Red Operators</h3>
                                        <span>Price On Application</span>
                                    </div>
                                    <div className="wpo-pricing-table">
                                         <ul>
                                            <li>Prices and Terms will be calculated on application.</li>
                                            <li>All fees will be payable on a flexible pay monthly contract.</li>
                                            <li>Acceptance is strictly subject to a full compliance audit &pound;150</li>
                                        </ul>
                                        <div className="wpo-pricing-footer">
                                            <div className="btns">
                                                <div className="btn-style btn-style-4"><Link to="/contact">Contact Now</Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-l2 col-md-6 col-12">
                                <div className="wpo-price-item">
                                    <div className="wpo-pricing-header">
                                        <h3>Restricted</h3>
                                        <span>£500 per month</span>
                                    </div>
                                    <div className="wpo-pricing-table">
                                         <ul>
                                            <li>Consultancy to assess your operational standards are inline with other licenses</li>
                                            <li>Per day or Contractual term pricing available on application.</li>
                                            <li>&nbsp;<br/></li>
                                        </ul>
                                        <div className="wpo-pricing-footer">
                                            <div className="btns">
                                                <div className="btn-style btn-style-4"><Link to="/contact">Contact Now</Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                    </div>
                </div>
                <div className="row" style={{marginTop: 30}}>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row">
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default PricingSection;
