import React from  'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb from '../../components/breadcumb'
import Features3 from '../../components/features3'
import PricingSection from '../../components/Pricing'
import FooterSection from '../../components/Footer'
import { Helmet } from 'react-helmet-async';

const OurServices = () => {
    return(
       <div>            
            <Helmet>
                <title>Our Services | LDH Transport Management</title>
                <link rel="canonical" href="https://www.ldhtransportmanagement.co.uk/ourservices" />
            </Helmet>
            <Navbar/>
            <Breadcumb bdtitle={'Our Services'} bdsub={'Our Services'}/>
            <Features3/>
            <PricingSection/>
            <FooterSection/>
       </div>
    )
}

export default OurServices;