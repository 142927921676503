import React from 'react';
import AllRoute from '../router'
import './App.css';

import { Helmet, HelmetProvider } from 'react-helmet-async';


const helmetContext = {};

const App = () => { 

  return (    
    <HelmetProvider context={helmetContext}>
      <div className="App">
        <Helmet>
          <title>Home | LDH Transport Management</title>
          <link rel="canonical" href="https://www.ldhtransportmanagement.co.uk/" />
        </Helmet>
        <AllRoute/>
      </div>
    </HelmetProvider>
  );
}
const { helmet } = helmetContext;

export default App;
