import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Homepage from '../HomePage'
import Aboutpage from '../AboutPage'
import OurServices from '../OurServices'
import ContactPage from '../ContactPage'
import FourZeroFour from '../FourZeroFour'
import ScrollToTop from '../../components/ScrollToTop';
const AllRoute = () => { 

  return (
    <div className="App">
       <Router>
        <ScrollToTop>
            <Switch>
                <Route exact path='/' component={Homepage} />
                <Route path='/home' component={Homepage} />
                <Route path='/about' component={Aboutpage} />
                <Route path='/ourservices' component={OurServices} />
                <Route path='/contact' component={ContactPage} />
                <Route component={FourZeroFour} />
            </Switch>
          </ScrollToTop>
      </Router>
      
    </div>
  );
}

export default AllRoute;
