import React from 'react';
import {Link} from 'react-router-dom'
import MobileMenu from '../../components/MobileMenu'
import logo from '../../images/logo/logo.png'

import './style.css'

const Header = () => {



    return(
            <header>
                <div className="header-top-1" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-12 col-12">
                                <ul className="d-flex account_login-area">                                    
                                    <li><i className="fa fa-map-marker"></i>Keighley, West Yorkshire</li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="btn-style"><a href="tel:+447957176299">Call Now on 07957 176 299</a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-style-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-10 col-sm-10 col-8 col-t">
                                <div className="logo">
                                    <Link to="/"><img src={logo} alt=""/></Link>
                                </div>
                            </div>
                            <div className="col-lg-8 d-none d-lg-block col-m">
                                <div className="main-menu">
                                    <nav className="nav_mobile_menu">
                                        <ul>
                                            <li className="active"><Link to="/">Home</Link>
                                            </li>
                                            <li><Link to="/about">About Us</Link></li>
                                            <li><Link to="/ourservices">Our Services</Link></li>
                                            <li><Link to="/contact">Contact</Link></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-1 col-2">
                                <MobileMenu/>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
     )
        
}

export default Header;
