import React from  'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb from '../../components/breadcumb'
import AboutSection2 from '../../components/about2'
import Mission from '../../components/Mission'
import FooterSection from '../../components/Footer'
import { Helmet } from 'react-helmet-async';

const Aboutpage = () => {
    return(
       <div>
            <Helmet>
                <title>About Us | LDH Transport Management</title>
                <link rel="canonical" href="https://www.ldhtransportmanagement.co.uk/about" />
            </Helmet>
            <Navbar/>
            <Breadcumb bdtitle={'About Us'} bdsub={'About'}/>
            <Mission/>
            <AboutSection2/>
            <FooterSection/>
       </div>
    )
}

export default Aboutpage;