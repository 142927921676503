import React from  'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb from '../../components/breadcumb'
import FooterSection from '../../components/Footer'


const Page404 = () => {
    return(
       <div>
           <Navbar/>
           <Breadcumb bdtitle={'404'} bdsub={'Page Not Found'}/>           
           <FooterSection/>
       </div>
    )
}

export default Page404;