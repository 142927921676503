import React from 'react';

import './style.css'

const ServiceArea = ( props ) => {

    return(
        <div className="wpo-service-s2">
            <div className="container">
                <div className="col-l2">
                    <div className="wpo-section-title text-center">
                        <span>We Provide the Best</span>
                        <h2>Our Services</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="service-green wpo-service-icon">
                                    <i className="fi flaticon-truck"></i>
                                </div>
                                <h2>Green Operators</h2>
                                <p>Operators who are determined as<br/>Low Risk</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="service-amber wpo-service-icon">
                                    <i className="fi flaticon-truck"></i>
                                </div>
                                <h2>Amber Operators</h2>
                                <p>Operators who are determined as<br/>Medium Risk</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="service-red wpo-service-icon">
                                    <i className="fi flaticon-truck"></i>
                                </div>
                                <h2>Red Operators</h2>
                                <p>Operators who are determined as<br/>High Risk</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="service-grey wpo-service-icon">
                                    <i className="fi flaticon-truck"></i>
                                </div>
                                <h2>Restricted Operators</h2>
                                <p>Consultancy to assess your operational standards</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default ServiceArea;
