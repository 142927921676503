import React from 'react';
/*import ContactForm2 from '../CommentForm2'*/

import serviceAreaImage from '../../images/about/service_area_map.png'
import './style.css'

const Contactpage = () => {

    return(
        <div className="contact-page-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-12">
                        <div className="contact-page-item">
                            <h2>Our Details</h2>
                            <p>If you think we can help your company with your Transport management needs then please contact us.</p>
                            <div className="adress">
                                <h3>Address</h3>
                                <span>Keighley, West Yorkshire, United Kingdom</span>
                            </div>
                            <div className="phone">
                                <h3>Phone</h3>
                                <span>Call Now on 07957 176 299</span>
                            </div>
                            <div className="email">
                                <h3>Email</h3>
                                <span>ldhtransportmanagement@gmail.com</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12">
                        <div className="contact-area contact-area-2 contact-area-3">
                            {/*<h2>Quick Contact Form</h2>
                            <ContactForm2/>*/}
                            <img src={serviceAreaImage} alt=""/>
                        </div>
                    </div>
                </div>
                {/*
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="contact-map">
                            <iframe title='db' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d37672.94909285799!2d-1.9510048!3d53.8329152!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bee92d6529fbb%3A0x99d34f235d235c6!2sHaworth%2C%20Keighley!5e0!3m2!1sen!2suk!4v1601582455470!5m2!1sen!2suk"></iframe>
                        </div>
                    </div>
                </div>
                        */}
            </div>
        </div>
     )
        
}

export default Contactpage;
