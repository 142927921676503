import React from 'react';
import {Link} from 'react-router-dom'

import './style.css'

const Features3 = () => {

    return(
        <div className="wpo-section-style-3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-9 col-sm-12 col-d c-pd">
                        <div className="section-wrap">
                            <h6>We offer the following services: </h6>
                            <div className="wpo-section-item-2">
                                <div className="service-green wpo-section-icon">
                                    <i className="fi flaticon-truck"></i>
                                </div>
                                <div className="wpo-section-content">
                                    <p><Link to="#">Green Operators</Link></p>
                                    <span>Operators who are determined as Low Risk</span>
                                </div>
                            </div>
                            <div className="wpo-section-item-2">
                                <div className="service-amber wpo-section-icon">
                                    <i className="fi flaticon-truck"></i>
                                </div>
                                <div className="wpo-section-content">
                                    <p><Link to="#">Amber Operators</Link></p>
                                    <span>Operators who are determined as Medium Risk</span>
                                </div>
                            </div>
                            <div className="wpo-section-item-2">
                                <div className="service-red wpo-section-icon">
                                    <i className="fi flaticon-truck"></i>
                                </div>
                                <div className="wpo-section-content">
                                    <p><Link to="#">Red Operators</Link></p>
                                    <span>Operators who are determined as High Risk</span>
                                </div>
                            </div>
                            <div className="wpo-section-item-2">
                                <div className="service-blue wpo-section-icon">
                                    <i className="fi flaticon-truck"></i>
                                </div>
                                <div className="wpo-section-content">
                                    <p><Link to="#">Restricted Operators</Link></p>
                                    <span>Consultancy to assess your operational standards</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="tr-wrap">
                            <div className="t-text">
                                <h2>Transport Management &amp; Consultancy</h2>
                                <p>Every business that holds, or is looking to obtain, a Standard National or Standard International Operator's licence must, 
                                by law, hire or employ a qualified and professional Transport Manager. This can be either a full time employee or an external, 
                                nominated holder of a CPC (Certificate of Professional Competence) in road haulage. </p>

                                <p>It isn't necessary to employ a full time transport manager, particularly if you are small to medium sized operator with a 
                                relatively small fleet (e.g 1-20 Vehicles). Outsourcing your CPC holder is an acceptable method of operator licence compliance 
                                and provides a cost-effective solution at the fraction of the price. </p>

                                <p>We can provide your business excellent transport management, CPC Holder services, nominated to your operator licence. We also have extensive experience in 
                                dealing with DVSA and the office of the Traffic Commissioner, so your operator licence will always be in reliable hands.</p>
                                <p>Whether your business is a start-up or well established, we will put you in position to obtain your operator's licence and run profitably fast.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default Features3;
